import React from 'react';
import qs from 'query-string';

import contentfulAPI from '../api/contentful-preview';

import CaseStudy from '../templates/case-study.js';

class PreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
    };
  }

  componentDidMount() {
    const params = qs.parse(window.location.search);
    const { id } = params;

    if (id) {
      contentfulAPI
        .getEntries({
          content_type: 'caseStudy',
          'sys.id': id,
          include: 8,
        })
        .then(response => this.setState({ document: response.items[0] }))
        .catch(error => {
          console.log('problem', error);
        });
    }
  }

  render() {
    if (this.state.document) {
      const { title, tags } = this.state.document.fields;

      const context = {
        data: this.state.document.fields,
        title: title,
        tags: tags,
      };

      return (
        <div>
          <CaseStudy pageContext={context} />
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default PreviewPage;
